import { calcPrice } from './calcPrice'
import { formatPrice, formatPriceWithoutCurrency } from './formatPrice'

export const formatLowAndHighPrice = (
  low: number,
  high: number,
  exchangeRates?: Record<string, number>,
  currency?: string
) => {
  if (!exchangeRates || !currency) {
    return ''
  }

  const lowPrice = calcPrice(low, exchangeRates[currency])
  const highPrice = calcPrice(high, exchangeRates[currency])
  if (lowPrice === null || highPrice === null) {
    return ''
  }

  return low === high
    ? formatPrice(lowPrice, currency, false)
    : `${formatPrice(lowPrice, currency, false)} - ${formatPriceWithoutCurrency(
        highPrice,
        currency
      )}`
}
