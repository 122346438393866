import { Fragment, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

import {
  IComparisonCardFields,
  IListItem,
} from 'bl-common/src/generated/contentful'
import { theme } from 'bl-common/src/styles/theme'
import { mediaObj } from 'bl-common/src/utils/media'
import { FlowComponent } from 'bl-flows-core'
import { MembershipType } from 'bl-graphql'
import { formatLowAndHighPrice } from 'bl-utils/src/currency/formatLowAndHighPrice'

import { AdmissionCardCarousel } from '../../../components/AdmissionCardCarousel'
import { globalBookingMessages } from '../../../messages'
import { admissionMessages } from '../messages/admission'
import { AdmissionCard, SIGNATURE_BACKGROUND } from './AdmissionCard'
import RetreatUpgradeCard from './RetreatUpgradeCard'

type AdmissionType = 'comfort' | 'premium' | 'signature' | 'retreat'

type AdmissionCardsType = Record<AdmissionType, IComparisonCardFields>

const AdmissionCardsWrapper = styled.div({
  gap: theme.spacing[2],
  paddingTop: theme.spacing[2],
  position: 'relative',
  display: 'none',

  [mediaObj.lg]: {
    display: 'flex',
    alignItems: 'flex-start',
  },
})

const CARD_ORDER: AdmissionType[] = [
  'comfort',
  'premium',
  'signature',
  'retreat',
]

const getAdmissionLookupArray = (
  admissionLookup: AdmissionCardsType
): (IComparisonCardFields & {
  admissionType: Exclude<AdmissionType, 'retreat'>
})[] => {
  return Object.keys(admissionLookup)
    .filter(key => key !== 'retreat')
    .map(key => {
      return {
        admissionType: key,
        ...admissionLookup[key],
      }
    })
    .sort(
      (a, b) =>
        CARD_ORDER.indexOf(a.admissionType) -
        CARD_ORDER.indexOf(b.admissionType)
    )
}

export const AdmissionCards = ({
  control,
  onSelect,
  membershipType,
}: FlowComponent & {
  onSelect?: (
    packageType:
      | 'comfort'
      | 'premium'
      | 'signature'
      | 'retreat'
      | 'subscription'
  ) => void
  membershipType?: MembershipType
}) => {
  const admissionLookup = control.context.admissionCards as AdmissionCardsType

  const admissionsArray = getAdmissionLookupArray(admissionLookup)
  const isMembership = Boolean(membershipType)
  const isProductsAvailabilityLoading =
    control.screen.setupHook.isProductsAvailabilityDataLoading
  const { prices } = control.screen.setupHook || {}
  const getLastComfortItem = (): IListItem => {
    const lastItem = [...(admissionLookup?.comfort?.listItems || [])].slice(-1)

    if (!lastItem?.[0]) {
      return null
    }

    return {
      ...lastItem[0],
      fields: {
        ...lastItem[0].fields,
        itemStyle: 'Additional',
      },
    }
  }

  const getListItems = (
    admissionType: AdmissionType,
    listItems?: IListItem[]
  ): Pick<IListItem, 'fields'>[] => {
    // Return an empty array if there are no list items
    if (!listItems || listItems.length === 0) {
      return []
    }

    // Early return since we don't have any special handling for non membership
    if (!isMembership) {
      return listItems
    }

    if (admissionType === 'comfort') {
      return listItems?.slice(0, -1)
    } else {
      return [
        ...(listItems?.filter(li => li.fields.item === 'Included') || []),
        {
          fields: {
            item: control.context.t(
              admissionMessages.info.everythingInMembershipAdmission
            ),
            itemStyle: 'Normal',
          },
        },
        ...[getLastComfortItem()],
        ...(listItems?.filter(li => li.fields.itemStyle === 'Additional') ||
          []),
      ]
    }
  }

  const admissionCards = useMemo(
    () =>
      admissionsArray.map(admission => {
        const { admissionType, image, listItems } = admission
        const isComfort = admissionType === 'comfort'
        const isPremium = admissionType === 'premium'
        const isSignature = admissionType === 'signature'
        const admissionKey =
          isMembership && isComfort ? 'subscription' : admissionType
        const price = prices?.[admissionType]
        const hasPrice = price?.low > 0 || price?.high > 0

        const admissionStatus = isProductsAvailabilityLoading
          ? 'loading'
          : hasPrice
            ? 'available'
            : 'fullyBooked'

        const statusTexts = {
          loading: {
            buttonText: null,
            subTitle: control.context.t(
              admissionMessages.info.priceFromPerPerson,
              {
                formattedPrice: <Skeleton inline width={70} />,
              }
            ),
          },
          available: {
            buttonText: control.context.t(globalBookingMessages.buttons.select),
            subTitle:
              admissionKey === 'subscription'
                ? control.context.t(globalBookingMessages.labels.included)
                : control.context.t(admissionMessages.info.priceFromPerPerson, {
                    formattedPrice:
                      hasPrice &&
                      formatLowAndHighPrice(
                        price?.low,
                        price?.high,
                        control.flow.setupHook?.exchangeRates,
                        control.flow.setupHook?.currency
                      ),
                  }),
          },
          fullyBooked: {
            buttonText: control.context.t(
              globalBookingMessages.text.timeSlotsFullyBooked
            ),
            subTitle: control.context.t(
              globalBookingMessages.text.timeSlotsFullyBooked
            ),
          },
        }

        return (
          <AdmissionCard
            key={admissionKey}
            exchangeRates={control.flow.setupHook?.exchangeRates}
            currency={control.flow.setupHook?.currency}
            onClick={() => {
              onSelect(admissionKey)
            }}
            title={control.context.t(admissionMessages.info[admissionKey])}
            image={image}
            buttonText={statusTexts[admissionStatus].buttonText}
            subtitle={statusTexts[admissionStatus].subTitle}
            admissionStatus={admissionStatus}
            label={
              isSignature
                ? control.context.t(admissionMessages.info.seasonal)
                : isPremium
                  ? control.context.t(globalBookingMessages.text.weRecommend)
                  : undefined
            }
            cardTheme={isSignature && { background: SIGNATURE_BACKGROUND }}
            listItems={getListItems(admissionType, listItems)}
          />
        )
      }),
    [prices]
  )

  return (
    <>
      <AdmissionCardCarousel>
        {admissionCards}
        <RetreatUpgradeCard
          onClick={() => onSelect('retreat')}
          control={control}
          image={admissionLookup?.retreat?.image}
        />
      </AdmissionCardCarousel>

      <AdmissionCardsWrapper>
        <>
          {admissionCards.map(card => (
            <Fragment key={card.key}>{card}</Fragment>
          ))}
          <RetreatUpgradeCard
            onClick={() => onSelect('retreat')}
            control={control}
            image={admissionLookup?.retreat?.image}
          />
        </>
      </AdmissionCardsWrapper>
    </>
  )
}
