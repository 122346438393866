import Skeleton from 'react-loading-skeleton'
import { Asset } from 'contentful'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { ButtonLink } from 'bl-common/src/richText/RichTextBlocks'
import { theme } from 'bl-common/src/styles/theme'
import { mediaObj } from 'bl-common/src/utils/media'
import { FlowComponent } from 'bl-flows-core'
import { formatLowAndHighPrice } from 'bl-utils/src/currency/formatLowAndHighPrice'

import { globalBookingMessages } from '../../../messages'
import { admissionMessages } from '../messages/admission'

const RetreatUpgradeWrapper = styled.div({
  background: colors.midGrey,
  backdropFilter: 'blur(10px)',
  color: colors.white,
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  minHeight: 370,
  [mediaObj.bmd]: {
    background: colors.midGrey,
  },
  [mediaObj.lg]: {
    minHeight: 'auto',
  },
})

const Content = styled.div<{
  hasImage?: boolean
}>(({ hasImage }) => ({
  padding: `${hasImage ? theme.spacing[2] : theme.spacing[4]} ${
    theme.spacing[4]
  } ${theme.spacing[4]} ${theme.spacing[4]}`,
  rowGap: theme.spacing[1],
  display: 'flex',
  flexDirection: 'column',

  [mediaObj.smd]: {
    padding: `${hasImage ? theme.spacing[2] : theme.spacing[4]} ${
      theme.spacing[2]
    } ${theme.spacing[2]} ${theme.spacing[2]}`,
  },
}))

const ImageContainer = styled.div({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: theme.spacing[20],
  position: 'relative',
  width: '100%',

  [mediaObj.md]: {
    height: theme.spacing[15],
  },
})

const RetreatUpgradeCard = ({
  control,
  onClick,
  image,
}: FlowComponent & {
  onClick: () => void
  image?: Asset
}) => {
  const { prices } = control.screen.setupHook || {}

  const { mediaBmd } = useBreakpoints()
  const hasImage = !!image && !mediaBmd
  const isProductsAvailabilityDataLoading =
    control.screen.setupHook.isProductsAvailabilityDataLoading
  const hasRetreatPrices = prices?.retreat?.low > 0 || prices?.retreat?.high > 0

  return (
    <RetreatUpgradeWrapper>
      {hasImage && (
        <ImageContainer>
          <ContentfulImage alt="Retreat" image={image} />
        </ImageContainer>
      )}
      <Content hasImage={hasImage}>
        <Type preset="headlineMedium">
          {control.context.t(admissionMessages.info.retreatUpgradeCardTitle)}
        </Type>
        {/* The 'notranslate' className below is required to avoid
            the screen from crashing when it's translated with Google Translate */}
        {(isProductsAvailabilityDataLoading || hasRetreatPrices) && (
          <Type preset="text" weight="bold" className="notranslate">
            {control.context.t(
              admissionMessages.info.retreatUpgradeCardSubtitle,
              {
                formattedPrice: hasRetreatPrices ? (
                  formatLowAndHighPrice(
                    prices.retreat.low,
                    prices.retreat.high,
                    control.flow.setupHook?.exchangeRates,
                    control.flow.setupHook?.currency
                  )
                ) : (
                  <Skeleton
                    baseColor={colors.darkmodeOffset}
                    highlightColor={colors.grey}
                    width={70}
                    inline
                  />
                ),
              }
            )}
          </Type>
        )}
        <Type preset="text">
          {control.context.t(admissionMessages.info.retreatUpgradeCardContent)}
        </Type>
        <ButtonLink
          type="button"
          preset="textSmall"
          weight="bold"
          onClick={onClick}
          textColor="blueOnDark"
        >
          {control.context.t(globalBookingMessages.buttons.checkAvailability)}
        </ButtonLink>
      </Content>
    </RetreatUpgradeWrapper>
  )
}

export default RetreatUpgradeCard
