import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { subDays } from 'date-fns'
import { addDays } from 'date-fns/addDays'
import { addMinutes } from 'date-fns/addMinutes'
import { isToday } from 'date-fns/isToday'
import capitalize from 'lodash/capitalize'

import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildBreadcrumbField,
  buildChangeDateField,
  buildCustomField,
  buildHeading,
  buildScreenWithFullScreenLayout,
  buildText,
} from 'bl-flows-core'
import {
  CartItemType,
  CartType,
  MembershipType,
  useProductsAvailabilityQuery,
} from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { MembershipCartType } from 'bl-utils/src/membership'
import {
  bluelagoonDayVisitProductIds,
  PRODUCT_IDS,
} from 'bl-utils/src/ProductIds'

import { globalBookingMessages } from '../../../messages/global'
import { selectDayVisitTimeFieldMessages } from '../../../utils/selectDayVisitTimeFieldMessages'
import { AdmissionCards } from '../components/AdmissionCards'
import SubscriptionCardAccepted from '../components/SubscriptionCardAccepted'
import { admissionMessages } from '../messages/admission'
import { getEcommerce } from '../utils/getEcommerce'

export const admissionScreen = ({
  screenTheme,
}: {
  screenTheme: ScreenTheme
}) => {
  return buildScreenWithFullScreenLayout({
    id: 'admission',
    subType: 'form',
    route: () => {
      return {
        en: 'admission',
        is: 'adgangur',
      }
    },
    theme: screenTheme,
    setupHook: control => {
      // Fetch product availability for the selected date
      const arrivalDate = new Date(control.flow.state.calendar?.arrivalDate)

      // Make sure that we always have a valid date
      // fromTime should never be in the past, otherwise we get an error from the API.
      // Adding 5 minutes to the current time as a buffer.
      const fromTime = isToday(arrivalDate)
        ? formatDateInUTC(addMinutes(new Date(), 5), 'HH:mm')
        : '00:00'

      const toTime = '23:59'

      // Format the date
      const date = formatDateInUTC(arrivalDate, 'yyyy-MM-dd')

      // Format the date with the time
      const formattedFromTime = setDateTimeISOString(date, fromTime)
      const formattedToTime = setDateTimeISOString(date, toTime)

      const {
        loading: isProductsAvailabilityDataLoading,
        data: productsAvailabilityData,
      } = useProductsAvailabilityQuery({
        variables: {
          input: {
            type: CartType.Dayspa,
            productIds: bluelagoonDayVisitProductIds,
            from: formattedFromTime,
            to: formattedToTime,
            isMembership:
              control.flow.setupHook?.cart?.membership?.membershipToken != null,
          },
        },
      })

      const prices = useMemo(() => {
        if (productsAvailabilityData == null) {
          return null
        }

        // Get cheapest and most expensive price for each product
        const sortedComfortPrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa => pa.available > 0 && pa.productId === PRODUCT_IDS.SpaComfort
            )
            .sort((a, b) => a.price - b.price)
        const sortedPremiumPrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa => pa.available > 0 && pa.productId === PRODUCT_IDS.SpaPremium
            )
            .sort((a, b) => a.price - b.price)
        const sortedSignaturePrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa =>
                pa.available > 0 && pa.productId === PRODUCT_IDS.SpaSignature
            )
            .sort((a, b) => a.price - b.price)
        const sortedRetreatPrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa => pa.available > 0 && pa.productId === PRODUCT_IDS.SpaRetreat
            )
            .sort((a, b) => a.price - b.price)

        const comfortPriceLow = sortedComfortPrices[0]?.price || 0
        const comfortPriceHigh =
          sortedComfortPrices.slice(-1)[0]?.price || comfortPriceLow || 0

        const premiumPriceLow = sortedPremiumPrices[0]?.price || 0
        const premiumPriceHigh =
          sortedPremiumPrices.slice(-1)[0]?.price || premiumPriceLow || 0

        const signaturePriceLow = sortedSignaturePrices[0]?.price || 0
        const signaturePriceHigh =
          sortedSignaturePrices.slice(-1)[0]?.price || signaturePriceLow || 0

        const retreatPriceLow = sortedRetreatPrices[0]?.price || 0
        const retreatPriceHigh =
          sortedRetreatPrices.slice(-1)[0]?.price || retreatPriceLow || 0

        return {
          comfort: {
            low: comfortPriceLow,
            high: comfortPriceHigh,
          },
          premium: {
            low: premiumPriceLow,
            high: premiumPriceHigh,
          },
          signature: {
            low: signaturePriceLow,
            high: signaturePriceHigh,
          },
          retreat: {
            low: retreatPriceLow,
            high: retreatPriceHigh,
          },
        }
      }, [productsAvailabilityData])

      return {
        productsAvailabilityData,
        isProductsAvailabilityDataLoading,
        prices,
      }
    },
    queryParams: control => {
      return {
        arrivalDate: control?.flow?.state?.calendar?.arrivalDate
          ? formatDateInUTC(control?.flow?.state?.calendar?.arrivalDate)
          : formatDateInUTC(new Date()),
      }
    },
    layoutProps: {
      id: 'fullscreen',
    },
    breadcrumb: control => {
      // We can't rely on setupHook here to figure out which admission we have
      // And also if the user refreshes the page we must rely on some URL state.
      const admissionType =
        window?.location?.pathname?.indexOf('/premium/') > 0
          ? 'Premium'
          : window?.location?.pathname?.indexOf('/comfort/') > 0
            ? 'Comfort'
            : window?.location?.pathname?.indexOf('/signature/') > 0
              ? 'Signature'
              : control.context.t(admissionMessages.info.subscription) // Winter & summer card?
      return {
        title: control.context.t(admissionMessages.info.breadcrumbTitle),
        value: admissionType,
      }
    },
    fields: {
      main: [
        buildBreadcrumbField({
          props: {
            breadcrumb: control => control.screen.breadcrumb,
          },
          layout: {
            spacing: {
              mb: { xs: 3 },
            },
          },
        }),
        buildCustomField({
          props: {
            render: () => {
              const { formatMessage } = useIntl()
              return (
                <SubscriptionCardAccepted
                  acceptedText={formatMessage(
                    globalBookingMessages.text.winterCardAccepted
                  )}
                  removeButtonLabel={formatMessage(
                    globalBookingMessages.buttons.remove
                  )}
                />
              )
            },
          },
        }),
        ...buildHeading({
          title: control =>
            control.context.t(admissionMessages.info.title, {
              admissionType: capitalize(
                control?.flow?.setupHook?.selectedPackage
              ),
            }),
          removeMarginTop: true,
          maxWidth: '100%',
        }),
        buildChangeDateField({
          id: 'selectedTime',
          defaultValue: null,
          props: {
            isLoadingAvailability: control =>
              control.screen.setupHook?.isProductsAvailabilityDataLoading,

            date: control => control.flow.state?.calendar?.arrivalDate,
            onPrevDayClick: control =>
              control.flow.setState({
                calendar: {
                  arrivalDate: subDays(
                    new Date(control.flow.state?.calendar?.arrivalDate),
                    1
                  ),
                },
              }),
            onNextDayClick: control =>
              control.flow.setState({
                calendar: {
                  arrivalDate: addDays(
                    new Date(control.flow.state?.calendar?.arrivalDate),
                    1
                  ),
                },
              }),

            messages: selectDayVisitTimeFieldMessages,
          },
          layout: {
            spacing: {
              mt: { xs: 2, md: 0 },
            },
          },
        }),
        buildCustomField({
          defaultValue: null,
          props: {
            component: props => {
              const membershipType =
                props.control.flow.setupHook?.cart?.membership?.type
              return (
                <AdmissionCards
                  membershipType={
                    [
                      MembershipCartType.WINTER_CARD,
                      MembershipCartType.WINTER_FAMILY_CARD,
                    ].includes(membershipType)
                      ? MembershipType.Winter
                      : [
                            MembershipCartType.SUMMER_CARD,
                            MembershipCartType.SUMMER_FAMILY_CARD,
                          ].includes(membershipType)
                        ? MembershipType.Summer
                        : undefined
                  }
                  onSelect={packageType => {
                    if (packageType === 'retreat') {
                      window.location.href =
                        props.control.context.locale === 'is'
                          ? '/is/boka/spa/retreat/guests'
                          : '/book/spa/retreat/guests'
                    } else {
                      props.control.flow?.setupHook?.setSelectedPackage(
                        packageType
                      )
                      props.control.flow.setState({
                        selectedPackage: packageType,
                      })
                      // this disables validation below when user has clicked on a package
                      props.control.screen.setUiState({
                        hasChosenNewPackage: true,
                      })
                      if (props.control.nextScreen()) {
                        const admissionPrice = {
                          comfort:
                            props.control.screen.setupHook?.prices?.comfort
                              ?.low,
                          premium:
                            props.control.screen.setupHook?.prices?.premium
                              ?.low,
                          signature:
                            props.control.screen.setupHook?.prices?.signature
                              ?.low,
                          subscription: 0,
                        }
                        triggerEvent({
                          event: 'select_item',
                          ecommerce: getEcommerce({
                            price: admissionPrice[packageType] || 0,
                            adults:
                              props.control.flow.state.guests?.adults || 1,
                            children:
                              props.control.flow.state.guests?.children || 0,
                            exchangeRates:
                              props.control.flow.setupHook?.exchangeRates,
                            productId:
                              packageType === 'premium'
                                ? PRODUCT_IDS.SpaPremium
                                : packageType === 'comfort'
                                  ? PRODUCT_IDS.SpaComfort
                                  : packageType === 'signature'
                                    ? PRODUCT_IDS.SpaSignature
                                    : packageType === 'subscription'
                                      ? PRODUCT_IDS.BLSummerCard
                                      : PRODUCT_IDS.SpaComfort,
                            spaType: packageType,
                          }),
                        })
                        triggerEvent({
                          event: 'funnel_admission',
                          BLType: 'Day Visit',
                          pageTitle: 'Book Admission',
                          pageCategory: 'book',
                          Adult: props.control.flow.state.guests?.adults || 1,
                          Children:
                            props.control.flow.state.guests?.children || 0,
                          Calendar: props.control.flow.state.calendar
                            ?.arrivalDate
                            ? formatDateInUTC(
                                new Date(
                                  props.control.flow.state.calendar?.arrivalDate
                                ),
                                'yyyy-MM-dd'
                              )
                            : 'MISSING',
                          Package: packageType,
                          language: props.control.context.locale,
                        })
                      }
                    }
                  }}
                  {...props}
                />
              )
            },
          },
          validation: {
            renderErrorInFieldRenderer: false,
            validator: (_, __, control) => {
              const admissionItem = control.flow.setupHook?.cart?.items?.find(
                item => item.type === CartItemType.Admission
              )

              if (
                admissionItem?.productId === PRODUCT_IDS.SpaRetreat &&
                !control.screen.uiStateRef?.current?.hasChosenNewPackage
              ) {
                // we are not rendering this validation on the screen, since its purpose is just to
                // stop the user on this screen if they have a Retreat admission item in their cart
                // that way they are forced to add their comfort/premium admission item
                // to the cart to replace the old one
                return 'Cannot continue with wrong admission item'
              }
            },
          },
        }),
        buildText({
          id: 'priceRangeDisclaimer',
          props: {
            value: control =>
              control.context.t(
                admissionMessages.warnings.priceRangeDisclaimer
              ),
            type: 'text',
            preset: 'labelTiny',
          },
          layout: {
            spacing: {
              mt: { xs: 1 },
            },
          },
        }),
      ],
    },
  })
}
