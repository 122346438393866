import { useEffect, useState } from 'react'

import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { spaBookingFlow } from 'bl-flows/src/flows/spa/spaBookingFlow'
import { useNextFlowRouter } from 'bl-flows/src/utils/useNextFlowRouter'
import { FlowRenderer } from 'bl-flows-core'
import { AdmissionItem, CartItemType } from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { queryTypes } from 'flow-builder/src/utils/urlState'

type SpaFlowRendererProps = {
  flowBasePath: string
  context: any
}

const schema = {
  type: queryTypes.string.withDefault('comfort'),
  adults: queryTypes.integer.withDefault(1),
  children: queryTypes.integer.withDefault(0),
  arrivalDate: queryTypes.date.withDefault(new Date()),
}

type SpaFlowInitialState = {
  arrivalDate?: string
  selectedTime?: string
}

const SpaFlowRenderer = ({ flowBasePath, context }: SpaFlowRendererProps) => {
  const [currentStep, updateStep, updateQueryParams] =
    useNextFlowRouter(flowBasePath)

  const [initialState, setInitialState] = useState<SpaFlowInitialState>()
  const [hasInitialLoaded, setHasInitialLoaded] = useState(false)

  const { cart } = useCartContext()

  const admissionItemInCart = cart.items.find(
    item => item.type === CartItemType.Admission
  ) as AdmissionItem

  useEffect(() => {
    if (admissionItemInCart && !hasInitialLoaded) {
      const arrivalTime = admissionItemInCart.meta?.arrivalTime

      if (arrivalTime) {
        setInitialState({
          arrivalDate: formatDateInUTC(arrivalTime, 'yyyy-MM-dd'),
          selectedTime: arrivalTime,
          ...(cart.customer?.firstName &&
            cart.customer?.lastName &&
            cart.customer?.email && {
              information: {
                nationality: cart.customer.nationality,
                receiveNewsLetter: cart.customer.newsletter,
                agreeToTerms: cart.membership ? false : true,
                primaryGuest: {
                  firstName: cart.customer.firstName,
                  lastName: cart.customer.lastName,
                  email: cart.customer.email,
                  phone: cart.customer.phone,
                },
              },
            }),
        })
      }
    }

    if (cart) {
      setHasInitialLoaded(true)
    }
  }, [cart])

  if (!hasInitialLoaded) {
    return (
      <SpinnerWrapper>
        <Spinner shouldAnimate />
      </SpinnerWrapper>
    )
  }

  return (
    <FlowRenderer
      flow={spaBookingFlow}
      context={context}
      onNavigation={updateStep}
      onQueryParamsChange={updateQueryParams}
      currentScreenPath={currentStep}
      stateFromUrlSchema={schema}
      initialFlowState={initialState}
    />
  )
}

export default SpaFlowRenderer
