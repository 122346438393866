import { Children, ReactNode } from 'react'
import styled, { createGlobalStyle, DefaultTheme } from 'styled-components'
import { Mousewheel, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { mediaObj } from 'bl-common/src/utils/media'

// Global styles for Swiper
const SwiperStyles = createGlobalStyle<{ theme: DefaultTheme }>`
:root {
  --swiper-pagination-color: ${colors.deepBlue};
  --swiper-pagination-bullet-inactive-color: ${colors.lightBlue};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 2.3rem;
  --swiper-pagination-bullet-height: 0.2rem;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-border-radius: 0px;
}
`

const CarouselMobileWrapper = styled.div(({ theme }) => ({
  overflow: 'hidden',
  marginLeft: `calc(-1 * ${theme.spacing[2]})`,
  marginRight: `calc(-1 * ${theme.spacing[2]})`,
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
  paddingTop: theme.spacing[2.5],

  [mediaObj.smd]: {
    paddingTop: theme.spacing[2],
  },
  [mediaObj.bmd]: {
    paddingTop: theme.spacing[2],
  },

  [mediaObj.mlg]: {
    marginLeft: `calc(-1 * ${theme.spacing[5]})`,
    marginRight: `calc(-1 * ${theme.spacing[5]})`,
    paddingLeft: theme.spacing[5],
    paddingRight: theme.spacing[5],
  },

  [mediaObj.lg]: {
    display: 'none',
  },
}))

const SwiperStyled = styled(Swiper)({
  height: '100%',
})

export const AdmissionCardCarousel = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <CarouselMobileWrapper>
      <SwiperStyles />
      <SwiperStyled
        style={{ overflow: 'visible', paddingBottom: '4rem' }}
        slidesPerView={1.25}
        initialSlide={1}
        centerInsufficientSlides
        centeredSlidesBounds
        centeredSlides
        pagination={true}
        mousewheel={{
          forceToAxis: true,
        }}
        spaceBetween={20}
        modules={[Pagination, Mousewheel]}
        breakpoints={{
          [breakpoints.smd]: {
            slidesPerView: 2.2,
            centeredSlides: false,
            slidesOffsetBefore: 30,
            initialSlide: 0,
          },
          [breakpoints.md]: {
            slidesPerView: 2.3,
            centeredSlides: false,
            slidesOffsetBefore: 50,
            initialSlide: 0,
          },
          [breakpoints.bmd]: {
            slidesPerView: 3.4,
            centeredSlides: false,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
            initialSlide: 0,
          },
          [breakpoints.mlg]: {
            slidesPerView: 3.3,
            centeredSlides: false,
            spaceBetween: 30,
            slidesOffsetBefore: 0,
            initialSlide: 0,
          },
        }}
      >
        {Children.map(children, child => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </SwiperStyled>
    </CarouselMobileWrapper>
  )
}
